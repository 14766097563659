
.section-title, .text_title {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 2.8rem;
}

.text_subtitle {
    color: $light-gray;
    font-weight: 500;
    font-size: 1.3rem;
}

.text_bold {
    font-weight: bold;
}