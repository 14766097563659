
.detail_header {
    align-items: center;
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin: 0;
    }
}

.detail_header-name {
    font-size: 4rem;
    font-weight: bold;
    font-style: italic;
}

.detail_header-price {
    font-weight: 300;
}


//* Specs section.

.detail_specs {
    min-height: 80vh;
    margin-bottom: 10vh;
}

.detail_specs-customization {
    // min-height: 100%;
    overflow: scroll;
    height: 80vh;
}

.detail_specs-slider {
    display: flex;
    justify-content: center;
    height: fit-content;
}

.detail_specs-list-container {
    border-left: 3px solid $primary;
    padding: 1rem;

    p {
        margin: 0;
    }
}

.detail_top-data {
    height: fit-content;
}

.detail_specs-color {
    border: 1px solid;
    border-color: gray;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.detail_specs-button {
    font-weight: 400;
    cursor: pointer;
}

.detail_specs-more {
    font-weight: 600;
}

.detail_final-price {
    color: black;
    // text-align: end;
}

.detail_autonomy {
    color: rgb(86, 86, 86);
}

.detail_carousel {
    background-color: rgb(238, 238, 238);
    border-radius: 15px;
    margin: auto;
    width: 100%;

}

.detail_action-button {
    background-color: black;
    color: white;
    cursor: pointer;
    padding: 20px 30px;
    text-decoration: none;

    &:hover {
        color: rgb(214, 214, 214);
    }

    a {
        color: white;
        text-decoration: none;
    }
}

//* Display Section

.display-item {
    height: 50vh;
    margin: 5vh 5vw;
}

//* Buy or Visit Section
.detail_visit-container {
    text-align: center;
}

//* Product Not Found

.detail_not_found {
    padding: 5rem;    
    text-align: center;
}


/*  Modal to show whoch battery to choose from.  */
.detail_choose_battery_modal {
    background-color: white;
    border-radius: 8px;
    color: black;
    margin: auto;
    max-height: 85vh;
    overflow: scroll;
    padding: 40px;
    width: 80vw;
}