
.title_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        font-weight: bold;
        margin: 0;
        text-align: center;
    }
}

.title_title {
    font-size: 2rem;
}

.title_subtitle {
    color: $primary;
}