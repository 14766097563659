// Fast solution for double background on modal. Improve code.

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
    display: none;
}

.modal-content {
    background-color: transparent;
    border: transparent;
    color: #FFFFFF;
    max-width: inherit;
}

.modal-dialog {
    max-width: 100%;
    width: 100vw;
    z-index: 3;
}

.modal-header {
    background-color: transparent;
    border: none;
    padding: 0;
}