/* 

    Autor: @Alejandro Vazquez del Mercado Gutiérrez.
    Fecha de Creación: 2 de Noviembre 2021.
    Autor de Modificación: @Sofía Verónica Ro.
    Modificación: Adaptado a React JS.
    Fecha de Modificación: 29 de Septiembre 2022.
    Página de Contacto para MotoElectrics.com.

*/

.contact-item a {
    color: black;
    text-decoration: none;
}

.contact-item img {
    margin-right: 1rem;
    width: 35px;
}

.contact-item.location img {
    margin-left: 5px;
    width: 25px;
}

.contact-title {
    color: #009FE3;
}

.header .logo {
    margin: auto;
    width: 70%;
}

.map {
    height: 50vh;
    width: 100%;
}