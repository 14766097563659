/* Youtube Videos Section */

.carousel_content {
    background-color: black;
    padding: 12vh 0;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .carousel_content {
        display: block;
    }
}

.carousel_content iframe {
    width: 910px;
    height: 500px;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 768px) {

    .carousel_content iframe {
        margin-top: 5%;
        width: 425px;
        height: 250px;
    }

}